<template>
  <div class="list">
    <div class="globle_border">
      <div class="search">
        <back></back>
        <div>
          <el-cascader v-model="value" :options="options" collapse-tags :props="{ expandTrigger: 'click', label: 'categoryName', value: 'categoryId', children: 'children', checkStrictly: false, emitPath: false, multiple: true }" @change="onSearch" clearable placeholder="选择分类"></el-cascader>
          <!-- <el-select v-model="purchaseStatus" placeholder="选择分类" clearable @change="onSearch">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select> -->
          <el-input v-model="supplierName" placeholder="请搜索供应商名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
          <el-input v-model="productName" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
          <el-input v-model="barCode" placeholder="请搜索条形码" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
          <el-input v-model="brand" placeholder="请搜索品牌" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
          <el-button type="primary" @click="dialogVisible = true">加入商品</el-button>
        </div>
      </div>
      <div style="margin-bottom: 20px">
        进货套餐名称：
        <span>{{ packageName }}</span>
      </div>
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" :row-key="row => row.id" ref="multipleTable" @selection-change="handleSelection">
          <el-table-column type="selection" width="55" :selectable="row => row.productBox > 0" :reserve-selection="true"></el-table-column>
          <el-table-column prop="number" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="supplierName" label="供应商名称" show-overflow-tooltip width="100"></el-table-column>
          <!-- <el-table-column prop="barCode" label="商品编号" show-overflow-tooltip></el-table-column> -->
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip width="180"></el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="specs" label="规格"></el-table-column>
          <el-table-column prop="categoryName" label="商品类目" show-overflow-tooltip></el-table-column>
          <el-table-column prop="enterBox" label="入箱数"></el-table-column>
          <!-- <el-table-column prop="factoryName" label="厂家"></el-table-column> -->
          <el-table-column prop="brand" label="品牌" show-overflow-tooltip></el-table-column>
          <el-table-column prop="productBox" label="进货箱数">
            <template slot-scope="scope">
              <el-input v-model="scope.row.productBox" placeholder="输入进货箱数" @change="handleBox(scope.row)"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="expirationDay" label="保质期(天)" width="100"></el-table-column>
          <el-table-column prop="supplyPrice" label="供货价(元)" width="100"></el-table-column>
          <el-table-column label="操作" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="danger" plain @click="handleDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div style=" margin-top: 50px;font-size: 20px; font-weight: bold;">
        合计：<span class="red">
          {{ amount }} 元
          <span class="gray">共 {{ numberBox }} 箱商品</span>
        </span>
        <div style="text-align: center; ">
          <el-button type="primary" @click="handleJoin" :disabled="multipleSelection.length == 0">下单</el-button>
        </div>
      </div>
    </div>

    <!-- 弹框 -->
    <el-dialog title="新增" :visible.sync="dialogVisible" width="80%">
      <div class="search-right">
        <div class="input-box">
          <el-input v-model="aPopoverProductName" placeholder="请输入商品名称" @change="onAPopoverCurrentPage" clearable></el-input>
        </div>
        <div class="input-box">
          <!-- <el-cascader placeholder="筛选分类" v-model="aPopoverCategoryId" collapse-tags :props="{ expandTrigger: 'hover', label: 'categoryName', value: 'categoryId', children: 'children', checkStrictly: true, emitPath: false
          , multiple: true }" :options="options1" @change="onAPopoverCurrentPage" clearable></el-cascader> -->
          <el-cascader v-model="aPopoverCategoryId" :options="options1" collapse-tags :props="{ expandTrigger: 'hover', label: 'categoryName', value: 'categoryId', children: 'categoryVoList', checkStrictly: false, emitPath: false, multiple: true }" @change="onAPopoverCurrentPage" clearable placeholder="搜索商品类目(二级)"></el-cascader>
        </div>
        <div class="input-box">
          <el-button type="primary" @click="changeAll" :disabled="allSelection.length == 0">批量加入</el-button>
        </div>
      </div>
      <div class="globle_table">
        <el-table :data="gridData" max-height="540" ref="tableRef" @selection-change="handleSelectionChange" row-key="productId">
          <el-table-column type="selection" width="60" :reserve-selection="true"></el-table-column>
          <el-table-column prop="supplierName" label="供应商名称" show-overflow-tooltip width="100"></el-table-column>
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip></el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="brand" label="品牌" show-overflow-tooltip></el-table-column>
          <el-table-column prop="factoryName" label="厂家" show-overflow-tooltip></el-table-column>
          <el-table-column prop="categoryName" label="分类" show-overflow-tooltip></el-table-column>
          <el-table-column prop="specs" label="规格" show-overflow-tooltip></el-table-column>
          <el-table-column prop="enterBox" label="入箱数" show-overflow-tooltip></el-table-column>
          <el-table-column prop="biddingPrice" label="供货价(元)" show-overflow-tooltip width="100"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right" width="100">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" size="small" @click="onWithdrawal(scope.row)">加入订单</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 商品弹窗分页 -->
      <div class="pagination">
        <el-pagination background @size-change="handleAPopoverSizeChange" @current-change="handleAPopoverCurrentChange" :current-page.sync="aPopoverCurrentPage" :page-size="aPopoverPageSize" :page-sizes="aPopoverPageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="aPopoverTotalCount"></el-pagination>
      </div>
    </el-dialog>
    <!-- 批量 -->
    <el-dialog title="加入订单" :visible.sync="dialogAll" :before-close="
        () => {
          dialogAll = false
        }
      ">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-form-item label="商品箱数" prop="productBox">
          <el-input-number v-model.number="ruleForm.productBox" :min="1" :step="1" :max="Infinity" @change="onchangeAll"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogAll = false">取 消</el-button>
        <el-button type="primary" @click="submitFormAll('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 单个 -->
    <el-dialog title="加入订单" :visible.sync="showD" :before-close="
        () => {
          showD = false
        }
      ">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" prop="shopName">
              <el-input v-model="ruleForm.shopName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品规格" prop="specs">
              <el-input v-model="ruleForm.specs" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="入箱数" prop="enterBox">
              <el-input v-model="ruleForm.enterBox" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品箱数" prop="productBox">
              <el-input-number v-model.number="ruleForm.productBox" :min="1" :step="1" :max="Infinity" @change="onchange"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品数量" prop="shopNumber">
              <el-input v-model="ruleForm.shopNumber" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="showD = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 提交 -->
    <el-dialog title="提交" :visible.sync="dialog" :before-close="
        () => {
          dialog = false
        }
      ">
      <el-form :model="ruleForms" :rules="rule" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="收货人" prop="receiverUser">
              <el-input v-model="ruleForms.receiverUser" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电话" prop="receiverPhone">
              <el-input v-model="ruleForms.receiverPhone" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="地址" prop="address">
              <el-input v-model="ruleForms.address" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="submitFormUser('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      amount: 0,
      numberBox: 0,
      dialog: false,
      dialogVisible: false,
      data: {},
      options: [],
      options1: [],
      brand: "",
      barCode: "",
      packageName: this.$route.query.packageName,
      supplierName: "",
      productName: "",
      tableData: [],
      gridData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      value: [],
      shopId: "",
      selectedOptions: [],
      productList: [],
      multipleSelection: [],
      ids: [],
      // 弹窗分页数据
      aPopoverCurrentPage: 1, // 当前页码
      aPopoverPageSize: 5, // 每页显示的行数
      aPopoverPageSizeArr: [5, 50, 100, 200, 300], // 每页显示的行数(可选)
      aPopoverTotalCount: 0, // 总记录数（需要从后端获取）
      allSelection: [], //弹框选中的数据
      aPopoverProductName: "", //弹窗商品名称搜索
      aPopoverCategoryId: null, //弹窗商品分类搜索
      showD: false, //弹框显示隐藏
      dialogAll: false, //批量弹框显示
      ruleForms: {
        receiverUser: "",
        address: "",
        receiverPhone: "",
      },
      ruleForm: {
        shopNumber: "",
        enterBox: "",
        specs: "",
        shopName: "",
        supplierId: "",
        productId: "",
        productBox: 1,
      },
      rule: {
        address: [{ required: true, message: "地址不能为空", trigger: "submit" }],
        receiverUser: [{ required: true, message: "收货人不能为空", trigger: "submit" }],
        receiverPhone: [
          { required: true, message: "数量不能少于", trigger: "submit" },
          {
            validator: function (rule, value, callback) {
              if (/^(13[0-9]|14[0-9]|15[0-9]|16[6]|18[0-9]|19[6,9]|17[0-9])\d{8}$/i.test(value) == false) {
                callback(new Error("请输入手机号"))
              } else {
                //校验通过
                callback()
              }
            },
            trigger: "blur",
          },
        ],
      },
      rules: {
        shopNumber: [
          { required: true, message: "数量不能少于", trigger: "submit" },
          {
            validator: function (rule, value, callback) {
              if (value === "" || value === isNaN) {
                callback(new Error("请输入一个数字"))
              } else if (!Number.isInteger(value)) {
                callback(new Error("请输入一个整数"))
              } else {
                callback()
              }
            },
            trigger: "blur",
          },
        ],
      },
    }
  },
  created() {
    this.shopId = this.$store.state.userInfo.shopId
    this.getPurchase_cart() // 商超列表
    this.getCategoryLIst()
    this.getCategoryListAll() //弹框分类
    this.getProductList() //弹框列表
    this.handleShop()
  },
  methods: {
    // 全选
    changeAll() {
      this.dialogAll = true
      // console.log('全选', this.allSelection);
    },
    // 获取商超联系方式
    handleShop() {
      this.$axios
        .get(this.$api.purchase_cart_shop_info, {
          params: {
            shopId: this.shopId,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.data = res.data.result
          }
        })
        .catch(() => { })
    },
    // 删除
    handleDel(row) {
      // console.log(row);
      this.$confirm(`是否确认删除?`, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.delete_purchase_cart, [row.id])
            .then(res => {
              if (res.data.code == 100) {
                this.tableData = []
                this.$message.success(res.data.desc)
                this.getPurchase_cart()
                this.$refs.multipleTable.toggleRowSelection(row, false)
                this.multipleSelection = JSON.parse(JSON.stringify(this.multipleSelection.filter(t => t.id != row.id)))
                this.handleAmount()
              }
            })
            .catch(() => { })
        })
        .catch(() => { })
    },
    onSelectable(row) {
      return row.quantity > 0
    },
    // 修改购物车
    handleBox(row) {
      if (row.productBox == 0) {
        this.$refs.multipleTable.toggleRowSelection(row, false)
      }
      let boolean = new RegExp("^[1-9][0-9]*$").test(row.productBox)
      if (!boolean) {
        this.$message.warning("请输入正整数")
        row.productBox == 0
        return
      }


      // console.log('row', row);

      this.$axios.post(this.$api.update_purchase_cart, {
          id: row.id,
          quantity: row.quantity,
          productBox: row.productBox,
          enterBox: row.enterBox,
          // productId: row.productId,
          // shopId: this.shopId,
          // supplierId: row.supplierId,
        })
        .then(res => {
          if (res.data.code == 100) {
            // this.$message.success('操作成功');
            // this.getSelect_purchase_cart()
            row.quantity = row.productBox * row.enterBox
            this.$refs.multipleTable.toggleRowSelection(row, false)
          }
        })
        .catch(() => { })
      // console.log('1', this.multipleSelection);
      // this.handleAmount()
      this.multipleSelection.forEach(t => {
        if (t.productId == row.productId) {
          t.quantity = row.quantity
          t.amount = row.quantity * row.supplyPrice
        }
      })
    },

    //
    handleJoin() {
      this.dialog = true
      this.ids = this.multipleSelection.map(t => t.id)
      if (this.data) {
        this.ruleForms.receiverUser = this.data.contactMan
        this.ruleForms.address = this.data.address
        this.ruleForms.receiverPhone = this.data.contactTel
      }
    },
    // 分类
    getCategoryLIst() {
      this.$axios
        .get(this.$api.selectProductCategory, {
          params: {
            id: this.$route.query.id,
          },
        })
        .then(res => {
          this.options = res.data.result
          this.options.map(item => {
            if (item.children.length == 0) {
              item.children = null
            }
          })
        })
        .catch(() => { })
    },
    // 商超列表
    getPurchase_cart() {
      this.$axios
        .post(this.$api.select_purchase_cart, {
          categoryIdList: this.value, //商品分类id
          // factoryName: this.factoryName,//厂家
          // barCode: this.barCode, //条形码 
          productName: this.productName, //商品名字
          barCode: this.barCode, //商品Code
          supplierName: this.supplierName, //供应商名字
          brand: this.brand,
          shopId: this.shopId, //商超
          page: this.currentPage,
          pageSize: this.pageSize,
          type: 2, //区分进货套餐
          code: this.$route.query.code, //对应code
        })
        .then(res => {
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
        })
        .catch(() => { })
    },
    // 商超列表搜索
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getPurchase_cart()
      console.log(this.value)
    },

    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getPurchase_cart()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getPurchase_cart()
    },
    // 选中数据
    handleSelection(val) {
      this.multipleSelection = val.map(t => ({
        quantity: t.quantity,
        supplyPrice: t.supplyPrice,
        productId: t.productId,
        id: t.id,
        amount: t.quantity * t.supplyPrice,
        productBox:  parseInt(t.productBox),
      }))
      this.handleAmount()
      console.log('选中', this.multipleSelection);

    },
    handleAmount() {
      console.log('合计', this.multipleSelection);
      const total = this.multipleSelection.reduce((accumulator, item) => accumulator + item.amount, 0)
      const numberBox = this.multipleSelection.reduce((accumulator, item) => accumulator + item.productBox, 0)
      // console.log(numberBox);
      this.numberBox = numberBox
      console.log('2', numberBox);
      this.amount = parseFloat(total.toFixed(2))
    },
    // 弹窗选中的数据
    handleSelectionChange(val) {
      this.allSelection = val
      // console.log(this.allSelection);
    },
    // 弹窗商品列表
    getProductList() {
      this.$axios
        .post(this.$api.purchaseList, {
          page: this.aPopoverCurrentPage,
          pageSize: this.aPopoverPageSize,
          productName: this.aPopoverProductName || null, //商品名称
          categoryIds: this.aPopoverCategoryId || null, //商品分类
          factoryName: this.factoryName || null,
          barCode: this.barCode || null,
          supplierName: this.supplierName || null,
        })
        .then(res => {
          this.gridData = res.data.result.list
          this.aPopoverTotalCount = res.data.result.totalCount
        })
    },
    // 弹窗分类列表获取
    getCategoryListAll() {
      this.$axios
        .get(this.$api.categoryLIst, {
          params: {
            // page: this.currentPage,
            // pageSize: 9999,
            // auditStatus: 1,
            // categoryName: this.categoryName || null,
          },
        })
        .then(res => {
          this.options1 = res.data.result.list
        })
    },
    // 加入订单
    onWithdrawal(row) {
      console.log(row)
      this.showD = true
      let data = Object.assign({}, row)
      this.number = data.enterBox
      // this.ruleForm.shopNumber = data.enterBox
      this.ruleForm.enterBox = data.enterBox
      this.ruleForm.specs = data.specs
      this.ruleForm.shopName = data.productName
      this.ruleForm.supplierId = data.supplierId
      this.ruleForm.productId = data.productId
      this.ruleForm.supplyPrice = data.supplyPrice
      this.ruleForm.shopNumber = row.enterBox * this.ruleForm.productBox
      // console.log(row.enterBox * this.ruleForm.shopNumber);
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {
        shopNumber: 1,
        enterBox: "",
        specs: "",
        shopName: "",
        supplierId: "",
        productId: "",
        productBox: "",
      }
      this.$refs.ruleForm.resetFields()
      this.$refs.tableRef.clearSelection()
    },
    //单个
    onchange(value) {
      // console.log(this.ruleForm.shopNumber);
      if (!Number.isInteger(value)) {
        this.ruleForm.productBox = Math.floor(value)
        this.$message.warning("只能输入整数")
      }
      this.ruleForm.shopNumber = this.ruleForm.enterBox * this.ruleForm.productBox
    },
    // 批量
    onchangeAll(value) {
      if (!Number.isInteger(value)) {
        this.ruleForm.productBox = Math.floor(value)
        this.$message.warning("只能输入整数")
      }
      this.ruleForm.shopNumber = this.ruleForm.enterBox * this.ruleForm.productBox
    },
    // 单个提交表单
    submitForm(formName) {
      const { productId, shopNumber, supplierId, productBox, supplyPrice } = this.ruleForm
      //将shopNumber，productId，字段存进productList[]数组里去重，
      // console.log(shopNumber, productBox);
      this.productList.push({
        productId: productId,
        quantity: shopNumber,
        supplierId: supplierId,
        productBox: productBox,
        supplyPrice: supplyPrice,
      })
      // console.log(this.productList);
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios
            .post(this.$api.save_purchase_cart, {
              type: 2,
              shopId: this.shopId,
              productList: this.productList,
              code: this.$route.query.code, //对应code
            })
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success("加入成功")
                this.productList = []
                this.getPurchase_cart()
                this.showD = false
                this.dialogVisible = false
                this.resetForm()
              }
            })
        } else {
          this.$message.error("加入失败")
          return false
        }
      })
    },
    // 批量提交
    submitFormAll(formName) {
      const { shopNumber, productBox } = this.ruleForm
      console.log(shopNumber, productBox)
      this.allSelection = this.allSelection.map(t => {
        t.productBox = productBox
        t.quantity = t.productBox * t.enterBox
        return t
      })
      // console.log('allSelection',this.allSelection);
      // 从this.allSelection 数组中提取enterBox字段，productBox字段，productId字段，supplierId字段，shopNumber字段返回数组
      this.productList = this.allSelection.map(t => {
        return {
          enterBox: t.enterBox,
          productBox: t.productBox,
          productId: t.productId,
          supplierId: t.supplierId,
          quantity: t.quantity,
          supplyPrice: t.supplyPrice
        }
      })
      // console.log(this.productList);
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios
            .post(this.$api.save_purchase_cart, {
              type: 2,
              shopId: this.shopId,
              productList: this.productList,
              code: this.$route.query.code, //对应code
            })
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success("加入成功")
                this.productList = []
                this.dialogAll = false
                this.dialogVisible = false
                this.resetForm()
                this.getPurchase_cart()
              }
            })
        } else {
          this.$message.error("加入失败")
          return false
        }
      })
    },
    // 下单
    submitFormUser(formName) {
      const { address, receiverPhone, receiverUser } = this.ruleForms
      let param = {
        address: address,
        receiverPhone: receiverPhone,
        receiverUser: receiverUser,
        shopId: this.shopId,
        cartIdList: this.ids,
        paymentPrice: 0,
      }
      // console.log(param);
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios.post(this.$api.shop_save_purchase_order, param).then(res => {
            if (res.data.code == 100) {
              this.$message.success("结算成功")
              this.dialog = false
              // this.resetForm();
              // this.amount = 0
              this.$refs.multipleTable.clearSelection()
              // 路由跳转
              this.$router.push({
                path: "/procure/purchasePackage",
                query: {},
              })
              // setTimeout(() => {
              // },500);
            }
          })
        } else {
          this.$message.error("结算失败")
          return false
        }
      })
    },
    // 弹窗分页初始
    onAPopoverCurrentPage() {
      this.gridData = []
      this.aPopoverCurrentPage = 1
      this.getProductList()
    },
    // 弹窗分页换页
    handleAPopoverCurrentChange(val) {
      this.aPopoverCurrentPage = val
      this.getProductList()
    },
    // 弹窗分页切换每页显示的条数
    handleAPopoverSizeChange(e) {
      this.aPopoverPageSize = e
      this.onAPopoverCurrentPage()
    },
  },
}
</script>

<style scoped lang="scss">
.list {
  .gray {
    color: #999;
    font-size: 14px;
    font-weight: normal;
  }
  .search {
    ::v-deep .back {
      padding: 0 !important;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    // height: 44px;

    .el-range-editor {
      width: 300px;
      margin-bottom: 20px;
    }

    .el-select,
    .el-input {
      margin-left: 20px;
      margin-bottom: 20px;
      width: 160px;
    }

    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .red {
    color: red;
  }

  .grey {
    color: grey;
  }
}
.search-right {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  .input-box {
    margin-left: 10px;
  }
}
/deep/ .el-cascader__search-input {
  background: transparent !important;
}
.el-input-number {
  width: 255px;
}
</style>
